<App>
    <Appbar class="bg-color-white">
        <div class="right">
            <Button color="blue bg-icon-logo"/>
        </div>
        <div class="left">
            <Button color="blue bg-icon-1"/>
            <Button small panelToggle="left" class="display-flex bg-text-color-1" iconF7="bars"/>
        </div>
    </Appbar>
    <Panel left>
        <Block>
            <p>Panel left content</p>
        </Block>
    </Panel>
    <View main>
        <Page hideToolbarOnScroll>
            <Block class="no-margin no-padding menu-item-container">
                <Block class="bg-top-panel no-margin no-padding">
                    <Row class="padding-left-half">
                        <Col xsmall-auto resizable-fixed class="text-align-right margin-top-half">
                            <div class="data-table">
                                <table class="no-margin no-padding">
                                    <thead>
                                    <tr>
                                        <td class="no-padding no-margin">
                                            <Card noShadow noBorder class="avatar-logo"/>
                                        </td>
                                        <td width="100%" class="no-padding no-margin">
                                            <Row>
                                                <Col class="text-color-white">
                                                    <div class="float-right">کاوند فرح مند</div>
                                                    <div class="float-left">
                                                        <Button text="زبان"
                                                                class="text-color-white no-margin no-padding"
                                                                popoverOpen=".popover-menu" large small>
                                                            <Icon f7="chevron_down" size=""
                                                                  class="margin-top-half"/>
                                                        </Button>
                                                        <Popover class="popover-menu">
                                                            <List>
                                                                <ListItem link="#" popoverClose title="Dialog"/>
                                                                <ListItem link="#" popoverClose title="Tabs"/>
                                                                <ListItem link="#" popoverClose
                                                                          title="Side Panels"/>
                                                                <ListItem link="#" popoverClose title="List View"/>
                                                                <ListItem link="#" popoverClose
                                                                          title="Form Inputs"/>
                                                            </List>
                                                        </Popover>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <hr class="bg-color-white"/>
                                        </td>
                                    </tr>
                                    </thead>
                                </table>
                            </div>
                        </Col>
                    </Row>
                </Block>
                <Row noGap class="main-3-menu">
                    <Col xsmall-auto resizable-fixed class="no-padding no-margin text-align-center">
                        <center>
                            <Card noShadow noBorder class="card-shadow wallet-card text-align-center">
                                <CardHeader class="no-border"/>
                                <CardFooter class="no-border">
                                    <Link class="text-color-black">کیف پول</Link>
                                </CardFooter>
                            </Card>
                        </center>
                    </Col>
                    <Col xsmall-auto resizable-fixed>
                        <center>
                            <Card noShadow="false" noBorder="false"
                                  class="card-shadow medical-certificate-card text-align-center">
                                <CardHeader class="no-border"/>
                                <CardFooter class="no-border">
                                    <Link class="text-color-black">مدارک پزشکی</Link>
                                </CardFooter>
                            </Card>
                        </center>
                    </Col>
                    <Col xsmall-auto resizable-fixed>
                        <center>
                            <Card noShadow="false" noBorder="false"
                                  class="card-shadow health-report-card text-align-center">
                                <CardHeader class="no-border"/>
                                <CardFooter class="no-border">
                                    <Link class="text-color-black">پیگیری درمان</Link>
                                </CardFooter>
                            </Card>
                        </center>
                    </Col>
                </Row>
            </Block>
            <Block class="">
                <Row tag="p">
                    <Col tag="span">
                        <Button large class="no-margin no-padding">
                            <Row class="no-margin no-padding width-100 six-menu-item" noGap>
                                <Col width="33" class="icon-right-1"/>
                                <Col width="66">خدمات درمانی</Col>
                            </Row>
                        </Button>
                    </Col>
                    <Col tag="span">
                        <Button large class="no-margin no-padding">
                            <Row class="no-margin no-padding width-100 six-menu-item" noGap>
                                <Col width="66">خدمات گردشگری</Col>
                                <Col width="33" class="icon-left-1"/>
                            </Row>
                        </Button>
                    </Col>
                </Row>
                <Row tag="p">
                    <Col tag="span">
                        <Button large class="no-margin no-padding">
                            <Row class="no-margin no-padding width-100 six-menu-item" noGap>
                                <Col width="33" class="icon-right-2"/>
                                <Col width="66">خدمات تخفیفی</Col>
                            </Row>
                        </Button>
                    </Col>
                    <Col tag="span">
                        <Button large class="no-margin no-padding">
                            <Row class="no-margin no-padding width-100 six-menu-item" noGap>
                                <Col width="66">خدمات بیمه</Col>
                                <Col width="33" class="icon-left-2"/>
                            </Row>
                        </Button>
                    </Col>
                </Row>
                <Row tag="p">
                    <Col tag="span">
                        <Button large class="no-margin no-padding">
                            <Row class="no-margin no-padding width-100 six-menu-item" noGap>
                                <Col width="33" class="icon-right-3"/>
                                <Col width="66">نقشه مسیریاب</Col>
                            </Row>
                        </Button>
                    </Col>
                    <Col tag="span">
                        <Button large class="no-margin no-padding">
                            <Row class="no-margin no-padding width-100 six-menu-item" noGap>
                                <Col width="66">مترجم</Col>
                                <Col width="33" class="icon-left-3"/>
                            </Row>
                        </Button>
                    </Col>
                </Row>
            </Block>
            <Block class="no-padding-top no-padding-top">
                <div class="ratio" data-ratio="5:3">
                    <div class="ratio-content">
                        <Swiper class="card-slider-show"
                                params={{
                                        loop: true,loopedSlides: 10,
                                        autoplay: {delay: 1000,reverseDirection:true}
                                    }}
                                pagination>
                            <!--pagination navigation-->
                            <SwiperSlide class="card-back"/>
                            <SwiperSlide class="card-front"/>
                        </Swiper>
                    </div>
                </div>
            </Block>


            <Toolbar tabbar labels position='bottom'>
                <Link small text="سرویس ها" panelToggle="left" class="display-flex bg-text-color-1" iconF7="square_stack_3d_up"/>
                <Link small text="خانه" tabLink="#" tabLinkActive class="display-flex bg-text-color-1" iconF7="house"/>
                <Link small text="خروج" class="display-flex bg-text-color-1" iconF7="arrow_left_to_line"/>
            </Toolbar>
        </Page>
    </View>
</App>

<script>
    import {App, Appbar, Block, Panel, View, Row, Button, Page, Col, Card} from 'framework7-svelte';
    import {CardFooter, CardContent, CardHeader} from 'framework7-svelte';
    import {Link, ListItem, List, Popup, Popover, Icon} from 'framework7-svelte';
    import {Swiper, SwiperSlide} from 'framework7-svelte';
    import {Toolbar, Fab, FabButton, FabButtons} from 'framework7-svelte';

    let isBottom = true;
</script>
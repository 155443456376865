export default {
    name: 'Ratio',
    create() {
        const app = this, UID = {
            _current: 0,
            getNew: function () {
                this._current++;
                return this._current;
            }
        }, Ratio = function () {
            HTMLElement.prototype.pseudoStyle = function (element, prop, value) {
                var _this = this;
                var _sheetId = "pseudoStyles";
                var _head = document.head || document.getElementsByTagName('head')[0];
                var _sheet = document.getElementById(_sheetId) || document.createElement('style');
                _sheet.id = _sheetId;
                var className = "pseudoStyle" + UID.getNew();

                _this.className += " " + className;

                _sheet.innerHTML += " ." + className + ":" + element + "{" + prop + ":" + value + "}";
                _head.appendChild(_sheet);
                return this;
            };

            const element = document.querySelectorAll('[data-ratio]');
            if (element.length > 0) {
                for (var i = 0; i < element.length; i += 1) {
                    var value = element[i].getAttribute("data-ratio");
                    if (value.length >= 3 && value.indexOf(":") > 0) {
                        var ratio = value.split(":");
                        if (ratio[0] > 0 && ratio[1] > 0) {
                            var h = ratio[1] * (100 / ratio[0]);
                            element[i].pseudoStyle("before", "padding-top", h + "%");
                        }
                    }
                }
            }
        };

        document.addEventListener('readystatechange', function (event) {
            // if (event.target.readyState === "interactive") {   //same as:  ..addEventListener("DOMContentLoaded".. and   jQuery.ready
            // }
            if (event.target.readyState === "complete") {
                Ratio();
            }
        });
    }
};